import "bootstrap-sass";

export default function (app) {
  let initialized = false;
  let smartActions = {};

  app.smartActions = smartActions;

  this.init = function () {
    if (!initialized) {
      initialized = true;

      smartActions = {
        userLogout($this) {
          function logout() {
            window.location = $this.attr("href");
          }

          $.SmartMessageBox({
            title: $this.data("logout-title") || '<i class="fa fa-sign-out txt-color-orangeDark"></i> Logout ?',
            content: $this.data("logout-msg") || "",
            buttons: `[${$this.data("button-no") || "No"}][${$this.data("button-yes") || "Yes"}]`
          }, (ButtonPressed) => {
            if (ButtonPressed === ($this.data("button-yes") || "Yes")) {
              $("body").addClass("animated fadeOutUp");
              setTimeout(logout, 1000);
            }
          });
        },
        launchFullscreen(element) {
          const $body = $("body");
          if (!$body.hasClass("full-screen")) {
            $body.addClass("full-screen");
            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen();
            }
          } else {
            $body.removeClass("full-screen");
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
            }
          }
        },
        toggleMenu() {
          $("html").toggleClass("hidden-menu-mobile-lock");
          $("body")
            .toggleClass("hidden-menu")
            .removeClass("minified");
        },
        resetWidgets($this) {
          $.SmartMessageBox({
            title: $this.data("reset-title") || "<i class='fa fa-refresh' style='color: green;'></i> Clear Local Storage",
            content: $this.data("reset-msg") || "Would you like to RESET all your saved widgets and clear LocalStorage?",
            buttons: `[${$this.data("button-no") || "No"}][${$this.data("button-yes") || "Yes"}]`
          }, (ButtonPressed) => {
            if (ButtonPressed === ($this.data("button-yes") || "Yes") && localStorage) {
              localStorage.clear();
              sessionStorage.clear();
              location.reload();
            }
          });
        },
        minifyMenu($this) {
          const $body = $("body");
          if (!$body.hasClass("menu-on-top")) {
            $body
              .toggleClass("minified")
              .removeClass("hidden-menu");
            $("html").removeClass("hidden-menu-mobile-lock");
            $this.effect("highlight", {}, 500);
          }
        }
      };

      $("body").on("click", '[data-action="userLogout"]', function (event) {
        let $this = $(this);
        smartActions.userLogout($this);
        event.preventDefault();
        $this = null;
      }).on("click", '[data-action="launchFullscreen"]', (event) => {
        smartActions.launchFullscreen(document.documentElement);
        event.preventDefault();
      }).on("click", '[data-action="toggleMenu"]', (event) => {
        smartActions.toggleMenu();
        event.preventDefault();
      })
        .on("click", '[data-action="resetWidgets"]', function (event) {
          let $this = $(this);
          smartActions.resetWidgets($this);
          event.preventDefault();

          $this = null;
        })
        .on("click", '[data-action="minifyMenu"]', function (event) {
          let $this = $(this);
          smartActions.minifyMenu($this);
          event.preventDefault();

          $this = null;
        });

      $("#search-mobile").on("click", () => {
        $("body").addClass("search-mobile");
      });

      $("#cancel-search-js").on("click", () => {
        $("body").removeClass("search-mobile");
      });
    }

    // activate tooltips
    try {
      $("[rel=tooltip]").tooltip();
    } catch {
      // noop
    }

    // activate popovers
    $("[rel=popover]").popover();

    // activate popovers with hover states
    $("[rel=popover-hover]").popover({
      trigger: "hover"
    });
  };

  this.destroy = function () {};
}
