import "jquery";
import "jQuery-QueryBuilder";
import "select2";

const Selectors = jQuery.fn.queryBuilder.constructor.selectors;

jQuery.fn.queryBuilder.define("autocomplete", function (options) {
  const appendSelect = function (rule) {
    const pluginConfig = rule.filter.plugin_config || {};

    const config = {
      width: "200px",
      ...pluginConfig
    };

    rule.$el.find(Selectors.rule_value).removeClass("form-control").select2(config);
  };

  this.on("afterCreateRuleInput", (event, rule) => {
    if (rule.filter.plugin !== "autocomplete") {
      return;
    }

    appendSelect(rule);
  });

  this.on("afterUpdateRuleValue", (event, rule) => {
    if (rule.filter.plugin !== "autocomplete") {
      return;
    }

    const pluginConfig = rule.filter.plugin_config || {};
    const selectOptions = rule.$el.find(Selectors.rule_value).find("option");

    if (rule.value && pluginConfig.ajax && !selectOptions.length) {
      jQuery.ajax({
        ...pluginConfig.ajax,
        data: {
          id: rule.value
        },
        success(data) {
          if (data.result) {
            const option = jQuery("<option />").attr({ value: data.result.id }).text(data.result.text);

            rule.$el.find(Selectors.rule_value).append(option);
          }

          appendSelect(rule);
        }
      });
    }
  });
});
