import "@SmartAdminBundle/js/smartwidgets/jarvis.widget";

const Widgets = (function (window, $) {
  const service = {};

  let settings = {
    // defaults
    enable: true,
    selector: {
      refresh: "#refresh",
      grid: "#widget-grid"
    },
    options: {
      grid: "article",
      widgets: ".jarviswidget",
      localStorage: true,
      deleteSettingsKey: "#deletesettingskey-options",
      settingsKeyLabel: "Reset settings?",
      deletePositionKey: "#deletepositionkey-options",
      positionKeyLabel: "Reset position?",
      sortable: true,
      buttonsHidden: false,
      toggleButton: true,
      toggleClass: "fa fa-minus | fa fa-plus",
      toggleSpeed: 200,
      onToggle() {
      },
      deleteButton: false,
      deleteClass: "fa fa-times",
      deleteSpeed: 200,
      onDelete() {
      },
      editButton: false,
      editPlaceholder: ".jarviswidget-editbox",
      editClass: "fa fa-cog | fa fa-save",
      editSpeed: 200,
      onEdit() {
      },
      colorButton: true,
      colorButtonRemove: {
        title: "",
        label: "Farbe entfernen"
      },
      colorButtonColors: [
        {
          class: "bg-color-green",
          widgetClass: "jarviswidget-color-green",
          title: ""
        },
        {
          class: "bg-color-greenDark",
          widgetClass: "jarviswidget-color-greenDark",
          title: ""
        },
        {
          class: "bg-color-greenLight",
          widgetClass: "jarviswidget-color-greenLight",
          title: ""
        },
        {
          class: "bg-color-purple",
          widgetClass: "jarviswidget-color-purple",
          title: ""
        },
        {
          class: "bg-color-magenta",
          widgetClass: "jarviswidget-color-magenta",
          title: ""
        },
        {
          class: "bg-color-pink",
          widgetClass: "jarviswidget-color-pink",
          title: ""
        },
        {
          class: "bg-color-pinkDark",
          widgetClass: "jarviswidget-color-pinkDark",
          title: ""
        },
        {
          class: "bg-color-blueLight",
          widgetClass: "jarviswidget-color-blueLight",
          title: ""
        },
        {
          class: "bg-color-teal",
          widgetClass: "jarviswidget-color-teal",
          title: ""
        },
        {
          class: "bg-color-blue",
          widgetClass: "jarviswidget-color-blue",
          title: ""
        },
        {
          class: "bg-color-blueDark",
          widgetClass: "jarviswidget-color-blueDark",
          title: ""
        },
        {
          class: "bg-color-darken",
          widgetClass: "jarviswidget-color-darken",
          title: ""
        },
        {
          class: "bg-color-yellow",
          widgetClass: "jarviswidget-color-yellow",
          title: ""
        },
        {
          class: "bg-color-orange",
          widgetClass: "jarviswidget-color-orange",
          title: ""
        },
        {
          class: "bg-color-orangeDark",
          widgetClass: "jarviswidget-color-orangeDark",
          title: ""
        },
        {
          class: "bg-color-red",
          widgetClass: "jarviswidget-color-red",
          title: ""
        },
        {
          class: "bg-color-redLight",
          widgetClass: "jarviswidget-color-redLight",
          title: ""
        },
        {
          class: "bg-color-white",
          widgetClass: "jarviswidget-color-white",
          title: ""
        }
      ],
      fullscreenButton: true,
      fullscreenClass: "fa fa-expand | fa fa-compress",
      fullscreenDiff: 3,
      onFullscreen() {
      },
      customButton: false,
      customClass: "folder-10 | next-10",
      customStart() {
      },
      customEnd() {
      },
      buttonOrder: "%refresh% %custom% %edit% %toggle% %fullscreen% %delete%",
      opacity: 1.0,
      dragHandle: "> header",
      placeholderClass: "jarviswidget-placeholder",
      indicator: true,
      indicatorTime: 600,
      ajax: false,
      timestampPlaceholder: ".jarviswidget-timestamp",
      timestampFormat: "Last update: %m%/%d%/%y% %h%:%i%:%s%",
      refreshButton: true,
      refreshButtonClass: "fa fa-refresh",
      labelError: "Sorry but there was a error:",
      labelUpdated: "Last Update:",
      labelRefresh: "Refresh",
      labelDelete: "Delete widget:",
      afterLoad() {
      },
      onChange() {
      },
      onSave() {
      },
      ajaxnav: true,
      toggleLabel: (
        window.app_widgets_localization_button_collapse !== undefined
          ? window.app_widgets_localization_button_collapse
          : "Collapse"
      ),
      deleteLabel: (
        window.app_widgets_localization_button_delete !== undefined
          ? window.app_widgets_localization_button_delete
          : "Delete"
      ),
      editLabel: (
        window.app_widgets_localization_button_edit !== undefined
          ? window.app_widgets_localization_button_edit
          : "Edit title"
      ),
      fullsceenLabel: (
        window.app_widgets_localization_button_fullscreen !== undefined
          ? window.app_widgets_localization_button_fullscreen
          : "Fullscreen"
      ),
      refreshLabel: (
        window.app_widgets_localization_button_refresh !== undefined
          ? window.app_widgets_localization_button_refresh
          : "Refresh"
      )
    }
  };

  service.Widgets = function () {
    let widgets = null;

    this.init = function () {
      if ($.fn.jarvisWidgets && settings.enable) {
        // place reset button
        $(settings.selector.refresh).show();

        $(settings.selector.grid).jarvisWidgets(settings.options);
        widgets = $(settings.selector.grid).data("jarvisWidgets");
      } else {
        // remove reset button container
        // (simple hiding will still trigger css rule adding padding to breadcrumbs)
        $(settings.selector.refresh).parent().remove();
      }
    };

    this.destroy = function () {
      if (widgets) {
        widgets.destroy();
      }
    };
  };

  return function (App, config) {
    const obj = this;
    const services = {};

    settings = $.extend(true, {}, settings, config);

    this.registerService = function (name) {
      if (service[name]) {
        services[name] = new service[name]();
      }
    };

    this.init = function () {
      obj.registerService("Widgets");

      Object.values(services).forEach((instance) => {
        instance.init();
      });
    };

    this.destroy = function () {
      Object.values(services).forEach((instance) => {
        instance.destroy();
      });
    };
  };
}(window, jQuery));

export default Widgets;
