import $ from "jquery";

/**
 * parses jsOptions "json" from <script type="application/json">.
 * Can contain functions and other JavaScript values like Date-Objects.
 * @see \Meeva\SmartAdminDatatableBundle\Helper\JavascriptEncoder
 */
export default function jsOptions($obj, def = {}) {
  if ($($obj).length) {
    const val = $($obj)[0].innerHTML;
    if (val) {
      try {
        // eslint-disable-next-line no-eval
        return eval(`(function() { return ${val}; })()`);
      } catch (e) {
        console.error("Invalid jsOptions 'json': ", e);
      }
    }
  }
  return def;
}
