import numeral from "numeral";
import numeral_de from "numeral/locales/de";
import moment from "moment";
import latinize from "latinize";
import $ from "jquery";
import _ from "lodash";
import jsOptions from "./helper/jsoptions";
import "datatables.net";
import "@SmartAdminBundle/js/plugin/datatables/dataTables.tableTools";
import "jQuery-QueryBuilder";
import "jQuery-QueryBuilder/dist/scss/default.scss";
import "jQuery-QueryBuilder/dist/i18n/query-builder.de.js";
import ColumnFilterPlugin from "./datatables_plugin_columnfilter";

const DataTables = (function (window) {
  numeral.locale("de", numeral_de);
  moment.locale("de");

  const contextualColor = function (options, callback) {
    if (!_.isObject(options.contextualColor) || !_.has(options.contextualColor, "operator")) {
      return callback;
    }

    return function (data) {
      const value = callback(data);
      if (options.contextualColor.operator && options.contextualColor.conditionValue !== undefined && (
        (options.contextualColor.operator === "gt" && data > options.contextualColor.conditionValue)
          || (options.contextualColor.operator === "gte" && data >= options.contextualColor.conditionValue)
          || (options.contextualColor.operator === "eq" && data === options.contextualColor.conditionValue)
          || (options.contextualColor.operator === "neq" && data !== options.contextualColor.conditionValue)
          || (options.contextualColor.operator === "lte" && data <= options.contextualColor.conditionValue)
          || (options.contextualColor.operator === "lt" && data < options.contextualColor.conditionValue)
      )) {
        if (options.contextualColor.textStrongIfConditionIsMet) {
          return `<span class="${options.contextualColor.colorClass}"><strong>${value}</strong></span>`;
        }
        return `<span class="${options.contextualColor.colorClass}">${value}</span>`;
      }
      return value;
    };
  };

  let settings = {
    // defaults
    selector: ".data-table",
    breakpointDefinition: {
      tablet: 1024,
      phone: 480
    },
    filter: {
      placeholder: "Filter",
      tooltip: 'You can use comparison operators such as "<5", ">=5", "!=5", "=5".',
      throttle: 600,
      simple: {
        placeholder: "Search",
        tooltip: "This column only supports basic searching from start of string."
      }
    },
    render: {
      fn: {
        boolean(options) {
          if (!options.className) {
            options.className = null;
          }
          return function (data) {
            return data && data !== "0"
              ? `<input type="checkbox" class="${options.className}" disabled checked />`
              : `<input type="checkbox" class="${options.className}" disabled />`;
          };
        },
        currency(options) {
          if (!options.format) {
            options.format = "0,0.00 $";
          }

          const fn = function (data, type) {
            if (data || data === 0) {
              if (type === "sort") {
                return data;
              }
              if (numeral) {
                return numeral(+data).format(options.format);
              }
              return (
                `${(data / 1).toFixed(2).replace(".", ",")} €`
              );
            }
            return options.fallback || null;
          };

          return contextualColor(options, fn);
        },
        percentage(options) {
          if (!options.precision) {
            options.precision = 2;
          }

          const fn = function (data) {
            if (data || data === 0) {
              return (
                `${(data / 1)
                  .toFixed(options.precision)
                  .replace(".", ",")} %`
              );
            }
            return options.fallback || null;
          };

          return contextualColor(options, fn);
        },
        progressbar(options) {
          options = $.extend(
            {
              numberFormat: "0,0.[00]",
              currentField: null,
              maxField: null,
              showLabel: true,
              labelPosition: "bottom",
              size: "sm",
              colors: []
            },
            options
          );

          return function (value, type, row) {
            if (value === null || value === undefined) {
              value = +row[options.currentField] / +row[options.maxField];
            }

            if (type === "sort") {
              return value;
            }
            const wrapper = $("<div>");

            const progressBar = $(
              `<div class="progress progress-${options.size || "sm"}"></div>`
            )
              .append(
                '<div class="progress-bar" role="progressbar" style="width: 0"></div>'
              )
              .find(".progress-bar");

            if (options.colors && options.colors.length) {
              const progressColor = $(options.colors)
                .filter((index, colorDef) => colorDef[0] >= (value * 100))
                .get()
                .sort((a, b) => a[0] - b[0]);

              if (progressColor && progressColor.length) {
                progressBar.addClass(`bg-color-${progressColor[0][1]}`);
              }
            }

            progressBar.css("width", `${value * 100}%`);
            wrapper.append(progressBar.end());

            if (options.showLabel && numeral) {
              const label = $(`<div class="text-nowrap progress-bar-text-${options.size || "sm"}">`)
                .text(
                  `${numeral(+row[options.currentField]).format(options.numberFormat)} / ${
                    numeral(+row[options.maxField]).format(options.numberFormat)
                  } (${numeral(+value).format("0.00%")})`
                );

              if (options.labelPosition === "top") {
                wrapper.prepend(label);
              } else {
                wrapper.append(label);
              }
            }

            return wrapper.get(0).outerHTML;
          };
        },
        number(options) {
          if (!options.format) {
            options.format = "0,0.[0000]";
          }

          const fn = function (data, type) {
            if (type === "sort") {
              return data;
            }
            if (data || data === 0) {
              if (numeral) {
                return numeral(+data).format(options.format);
              }
              return data.toLocaleString();
            }
            return options.fallback || null;
          };

          return contextualColor(options, fn);
        },
        integer(options) {
          if (!options.format) {
            options.format = "0,0.[00]";
          }
          const fn = function (data, type) {
            if (type === "sort") {
              return data;
            }
            if (data || data === 0) {
              if (numeral) {
                return numeral(+data).format(options.format);
              }
              return (data / 1).toFixed(0);
            }
            return options.fallback || null;
          };

          return contextualColor(options, fn);
        },
        moment(options) {
          if (!options.format) {
            options.format = "YYYY-MM-DD HH:mm:ss";
          }

          return function (data, type) {
            if (type === "sort") {
              return data;
            }
            if (data) {
              const m = options.fromUtc
                ? moment.utc(data).local()
                : moment(data);
              return m.format(options.format);
            }
            return data || (options.fallback || null);
          };
        },
        momentAgo(options) {
          return function (data, type) {
            if (type === "sort") {
              return data;
            } if (data) {
              const m = options.fromUtc
                ? moment.utc(data).local()
                : moment(data);
              return m.fromNow(options.dropSuffix || false);
            }
            return data || (options.fallback || null);
          };
        },
        momentDuration(options) {
          options = $.extend(
            {
              humanize: false,
              timeUnit: "seconds"
            },
            options
          );

          return function (data, type) {
            if (type === "sort") {
              return data;
            }
            if (data) {
              return moment
                .duration(data, options.timeUnit)
                .humanize(options.humanize);
            }
            return data || (options.fallback || null);
          };
        },
        date(options) {
          if (!options.format) {
            options.format = "Y-m-d H:i:s";
          }

          return function (data, type) {
            if (type === "sort") {
              return data;
            }

            if (data) {
              if ($.isNumeric(data)) {
                data = Number(data);
                if (!options.useMilliseconds) {
                  // convert timestamp to milliseconds for javascript date object
                  data *= 1000;
                }
              }
              const date = new Date(data);
              if (!isNaN(date)) {
                // uses date.format.js library
                return date.format(options.format);
              }
            }
            return data || (options.fallback || null);
          };
        },
        default(options) {
          if (!options.value) {
            options.value = "";
          }

          return function (data) {
            if (data) {
              return data;
            }
            return options.value;
          };
        },
        list(options) {
          options = $.extend(
            {
              multi: false,
              options: {},
              separator: ",",
              displayMulti(arr) {
                return arr.join(", ");
              }
            },
            options
          );
          const getLabel = function (value, type, row) {
            let o = options.options;
            if (typeof o === "function") {
              o = o.call(null, options, value, type, row);
            }
            return typeof o[value] !== "undefined"
              ? o[value]
              : value;
          };

          let render = getLabel;
          if (options.multi) {
            render = function (data, type, row) {
              if (!Array.isArray(data)) {
                if (data) {
                  data = data.toString().split(options.separator);
                } else {
                  data = [];
                }
              }
              for (let i = 0, l = data.length; i < l; i++) {
                data[i] = getLabel(data[i], type, row);
              }
              return options.displayMulti(data);
            };
          }
          render.options = options.options;
          return render;
        },
        link(options) {
          options = $.extend(
            {
              url_field: null,
              className: null,
              target: null,
              tabIndex: 0
            },
            options
          );

          return function (value, type, row) {
            if (options.url_field && row[options.url_field]) {
              const a = $("<a>")
                .text(value)
                .attr("href", row[options.url_field])
                .attr("target", options.target)
                .attr("tabIndex", options.tabIndex)
                .addClass(options.className);
              return a[0].outerHTML;
            }
            return value;
          };
        }
      }
    },
    defaults: {
      sDom:
                "<'dt-toolbar'<'col-xs-8 col-sm-6'f><'col-xs-4 col-sm-6'<'toolbar'T>l>>"
                + "<'dt-table-wrapper'tr>"
                + "<'dt-toolbar-footer'<'row'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>>",
      language: {
        url:
                    window.app_datatables_localization !== undefined
                      ? window.app_datatables_localization
                      : null
      },
      oTableTools: {
        aButtons: []
      },
      orderClasses: false,
      order: [],
      stateSave: true,
      linkMode: "row", // Custom parameter: link whole 'row' or create a 'button' column or both using 'row-button'
      buttonRenderer: null // Customer parameter: render function for 'button' mode column
    }
  };

  let AppContainer;

  const prepareOptions = function (options) {
    if (typeof options.columns !== "undefined") {
      const columns = [];
      _.each(options.columns, (column) => {
        if (column !== null) {
          if (
            _.isObject(column.render)
                        && _.has(column.render, "fn")
          ) {
            if (
              _.isObject(settings.render)
                            && _.isObject(settings.render.fn)
                            && _.isFunction(settings.render.fn[column.render.fn])
            ) {
              column.render = settings.render.fn[
                column.render.fn
              ](_.omit(column.render, "fn"));
            } else if (
              _.isObject(options.render)
                            && _.isObject(options.render.fn)
                            && _.isFunction(options.render.fn[column.render.fn])
            ) {
              column.render = options.render.fn[column.render.fn](
                _.omit(column.render, "fn")
              );
            } else {
              window.console.warn(
                "DataTables: no render function found for:"
              );
              window.console.log(column.render);
            }
          }
        }

        columns.push(column);
      });

      options.columns = columns;
    }

    // add ajax error handler
    if (
      typeof options.ajax === "object"
            && typeof options.ajax.error === "undefined"
    ) {
      options.ajax.error = function (xhr, status, error) {
        window.console.log(xhr, status, error);
        if (typeof AppContainer.displayNotification !== "undefined") {
          AppContainer.displayNotification(
            `Error ${xhr.status}`,
            error,
            "error",
            5000
          );
        }
      };
    }

    return options;
  };

  const createTableToolsExports = function () {
    $.fn.dataTable.TableTools.buttons.download = {
      sAction: "text",
      sTag: "default",
      sFieldBoundary: "",
      sFieldSeperator: "\t",
      sNewLine: "<br>",
      sToolTip: "",
      sButtonClass: "DTTT_button_text",
      sButtonClassHover: "DTTT_button_text_hover",
      sButtonText: "Download",
      mColumns: "all",
      bHeader: true,
      bFooter: true,
      sDiv: "",
      fnMouseover: null,
      fnMouseout: null,
      fnClick(nButton, oConfig) {
        // eslint-disable-next-line no-underscore-dangle
        const oParams = this.s.dt.oApi._fnAjaxParameters(this.s.dt);
        // append column titles
        for (let i = 0; i < oParams.columns.length; i++) {
          oParams.columns[i].title = this.s.dt.aoColumns[i].sTitle;
        }
        // iframe for download
        const iframe = document.createElement("iframe");
        iframe.style.height = "0px";
        iframe.style.width = "0px";
        iframe.src = `${oConfig.sUrl}?${$.param(oParams)}`;
        document.body.appendChild(iframe);
      },
      fnSelect: null,
      fnComplete: null,
      fnInit: null
    };
  };

  const attachFilters = function () {
    const formatData = function (num) {
      return parseFloat(
        $.trim(num.replace(/€+|%+/, ""))
          .replace(".", "")
          .replace(",", ".")
      );
    };

    const formatDataFilter = function (filter) {
      return formatData(filter.match(/(-?\d+(([.,])\d*)?)/)[0]);
    };

    $.fn.dataTableExt.afnFiltering.push((dtSettings, data, iDataIndex) => {
      const filters = $(dtSettings.nTable).find("thead tr.filters th");
      let filter;
      let val;
      let val2;

      if (!filters.length) {
        return true;
      }

      for (let i = 0; i < data.length; i++) {
        filter = $(filters.get(i)).find("input, select");
        if (!(filter && filter.length)) {
          continue;
        }
        filter = filter.val().replace(/(^\s*)|(\s*$)/g, "");

        // Operator less than
        if (filter.match(/^<\s*-?(\d+(([.,])\d*)?)$/)) {
          val = formatDataFilter(filter);
          val2 = formatData(data[i]);
          if (Number.isNaN(val2) || val2 >= val) {
            return false;
          }
          // Operator less than or equal to
        } else if (filter.match(/^<=\s*-?(\d+(([.,])\d*)?)$/)) {
          val = formatDataFilter(filter);
          val2 = formatData(data[i]);
          if (Number.isNaN(val2) || val2 > val) {
            return false;
          }
          // Operator greater than
        } else if (filter.match(/^>\s*-?(\d+(([.,])\d*)?)$/)) {
          val = formatDataFilter(filter);
          val2 = formatData(data[i]);
          if (Number.isNaN(val2) || val2 <= val) {
            return false;
          }
          // Operator greater than or equal to
        } else if (filter.match(/^>=\s*-?(\d+(([.,])\d*)?)$/)) {
          val = formatDataFilter(filter);
          val2 = formatData(data[i]);
          if (Number.isNaN(val2) || val2 < val) {
            return false;
          }
          // Operator not equal
        } else if (
          filter.match(/^<>\s*-?(\d+(([.,])\d*)?)/)
                    || filter.match(/^!=\s*(\d+(([.,])\d*)?)/)
        ) {
          val = formatDataFilter(filter);
          if (formatData(data[i]).toFixed(5) === val.toFixed(5)) {
            return false;
          }
          // Operator equal to
        } else if (filter.match(/^=\s*-?(\d+(([.,])\d*)?)$/)) {
          val = formatDataFilter(filter);
          if (formatData(data[i]).toFixed(5) !== val.toFixed(5)) {
            return false;
          }
          // Operator range (2-8)
        } else if (
          filter.match(/^-?(\d+(([.,])\d*)?)\s*-\s*-?(\d+(([.,])\d*)?)$/)
        ) {
          val = formatData(filter.match(/^(\d+(([.,])\d*)?)/)[0]);
          val2 = formatData(filter.match(/(\d+(([.,])\d*)?)$/)[0]);
          if (Number.isNaN(formatData(data[i])) || formatData(data[i]) < val || formatData(data[i]) > val2) {
            return false;
          }
        } else if (filter !== "") {
          const dtColumns = dtSettings.oInstance.data("options").columns;
          const dataRenderer = dtColumns[i].render;
          if (dataRenderer && dataRenderer.fn === "boolean") {
            // eslint-disable-next-line no-underscore-dangle
            const rawData = dtSettings.aoData[iDataIndex]._aData[dtColumns[i].data] ? "1" : "0";
            if (rawData !== filter) {
              return false;
            }
          } else if (!latinize((`${data[i]}`)).match(new RegExp(latinize(filter), "i"))) {
            return false;
          }
        }
      }

      return true;
    });
  };

  const appendLinkRow = function ($obj, url, data) {
    if (url || data.url_detail) {
      $obj.css({ cursor: "pointer" }).on("click", function (event) {
        if (!$(event.target).is("a[href], button:not(:disabled)") && $(event.target).parents("a[href], button:not(:disabled)").length === 0) {
          if (!$(this).hasClass("responsiveExpander")) {
            event.preventDefault();
            // 'which' for gecko, webkit, opera; 'button' for ie, opera
            if (event.which === 2 || event.which === 3) {
              // do nothing on right or mouse button
              return;
            }
            if (event.ctrlKey || event.shiftKey) {
              window.open(data.url_detail || url);
            } else {
              AppContainer.goTo(data.url_detail || url);
            }
          }
        }
      });
    }
  };

  const appendLinkButton = function ($table, options) {
    let renderFunction = function (data, type, row) {
      // eslint-disable-next-line no-underscore-dangle
      if (!row._buttons && row.url) {
        const button = $("<a />")
          .attr({
            href: row.url,
            class: "btn btn-warning btn-xs"
          })
          .html(
            $("<i />").attr({
              class: "fa fa-edit"
            })
          );

        return button[0].outerHTML;
      }

      // eslint-disable-next-line no-underscore-dangle
      return (row._buttons || []).map((item) => {
        item.href = item.href || item.url || "";
        item.class = `btn btn-xs ${item.class || item.classes || ""}`;

        const button = $("<a />")
          .attr(item)
          .html(
            $("<i />").attr({
              class: `fa fa-fw ${item.icon || ""}`
            })
          );

        return button[0].outerHTML;
      }).join("");
    };

    if (typeof options.buttonRenderer === "function") {
      renderFunction = options.buttonRenderer;
    }

    options.columns.push({
      className: "text-nowrap",
      data: null,
      sortable: false,
      render: renderFunction
    });

    $table
      .find("thead tr")
      .append('<th class="minimal" data-filter-type="none"></th>');
  };

  const rebindSearchInput = function (tableApi) {
    if (!tableApi) {
      return;
    }
    const $container = $(tableApi.table().container());

    const debouncedSearch = _.debounce((value) => {
      tableApi.search(value).draw();
    }, 2000);

    $container
      .find(".dataTables_filter input")
      .unbind()
      .bind("input keyup", (event) => {
        if (event.keyCode === 13) {
          debouncedSearch(event.target.value);
          debouncedSearch.flush();
        }

        debouncedSearch(event.target.value);
      });
  };

  const initializeQueryBuilderPresets = function (table, tableId, qbPresets) {
    let localPresets = [];
    const actionDelete = qbPresets.find(".action-delete");
    const actionSave = qbPresets.find(".action-save");
    const presetSelector = qbPresets.find("select");

    function loadLocalStoragePresets(preselect) {
      localPresets = JSON.parse(window.localStorage.getItem(`qbPresets#${tableId}`) || "[]");
      presetSelector.find("optgroup.custom-presets").remove();

      if (!localPresets.length) {
        presetSelector.find("option:first").prop("selected", true);
        actionDelete.addClass("disabled");
        return;
      }

      // noinspection RequiredAttributes
      const customPresets = $("<optgroup></optgroup>");
      customPresets
        .attr("label", "Eigene Vorlagen")
        .addClass("custom-presets");

      localPresets.forEach((localPreset) => {
        const option = $("<option></option>");
        option
          .text(localPreset.name)
          .data("preset", "localstorage")
          .data("rules", localPreset.rules);
        customPresets.append(option);
      });

      presetSelector.append(customPresets);

      if (preselect) {
        presetSelector.find("option").filter((i, e) => $(e).text() === preselect).prop("selected", true);
        presetSelector.trigger("change");
      } else {
        presetSelector.find("option:first").prop("selected", true);
      }
    }

    loadLocalStoragePresets();

    presetSelector.on("change", function () {
      const selectedPreset = $(this).find("option:selected");
      table.data("queryBuilder").queryBuilder("setRules", selectedPreset.data("rules"));

      actionDelete.toggleClass("disabled", selectedPreset.data("preset") === "preferred");

      table.dataTable().api(true).draw();
    });

    actionDelete.on("click", () => {
      const selectedPreset = presetSelector.find("option:selected");
      if (!selectedPreset.length || selectedPreset.data("preset") === "preferred") {
        actionDelete.addClass("disabled");
        return;
      }

      localPresets = localPresets.filter(preset => preset.name !== selectedPreset.text());

      window.localStorage.setItem(`qbPresets#${tableId}`, JSON.stringify(localPresets));
      loadLocalStoragePresets();
    });

    actionSave.on("click", () => {
      const newPreset = window.confirm("Möchten Sie eine neue Vorlage erstellen?");

      const selectedPreset = presetSelector.find("option:selected");
      if (!selectedPreset.length || selectedPreset.data("preset") === "preferred") {
        if (!newPreset) {
          window.alert("Diese Vorlage kann nicht überschrieben werden, bitte erstellen Sie eine neue Vorlage!");
          return;
        }
      }

      let presetName;
      if (newPreset) {
        presetName = window.prompt("Bitte geben Sie den Namen der neuen Vorlage ein:");
        if (!presetName || presetName.trim().length === 0) {
          return;
        }

        localPresets.push({
          name: presetName.trim(),
          rules: table.data("queryBuilder").queryBuilder("getRules")
        });
      } else {
        presetName = selectedPreset.text();
        localPresets.forEach((localPreset) => {
          if (localPreset.name !== presetName) {
            return;
          }

          localPreset.rules = table.data("queryBuilder").queryBuilder("getRules");
        });
      }

      window.localStorage.setItem(`qbPresets#${tableId}`, JSON.stringify(localPresets));
      loadLocalStoragePresets(presetName);
    });
  };

  const initializeQueryBuilder = function (tableId, table) {
    let queryBuilderHashKey;
    if (table.data("options").qbConfiguration.saveState) {
      queryBuilderHashKey = `DataTablesQueryBuilder_${tableId}_${location.pathname}`;
    }
    const $queryBuilder = $(`#${tableId}_querybuilder`);
    if (!$queryBuilder.length) {
      console.error(`Querybuilder markup for datatable ${tableId} not found. Ensure you have included SmartAdminDatatableBundle:Datatable:querybuilder.html.twig if you pass qbConfiguration to datatable-options`);
      return;
    }
    table.data(
      "queryBuilder",
      $queryBuilder.queryBuilder(table.data("options").qbConfiguration)
    );
    if (queryBuilderHashKey) {
      const rqb = window.localStorage.getItem(queryBuilderHashKey);
      if (typeof rqb === "string") {
        try {
          table.data("queryBuilder").queryBuilder("setRules", JSON.parse(rqb));
        } catch (e) {
          console.error("Could not restore jsQueryBuilder saved state", e);
        }
      }
    }

    const currentRules = table.data("queryBuilder").queryBuilder("getRules");
    if (currentRules && currentRules.rules.length > 0) {
      // expand widget if rules aren't empty
      const jv = $queryBuilder.closest(".jarviswidget").attr("data-widget-collapsed", "false");
      if (jv.is(".jarviswidget-collapsed")) {
        jv.find(".jarviswidget-toggle-btn").click();
      }
    }

    $(`#${tableId}_querybuilder_apply`).click(() => {
      const rules = table.data("queryBuilder").queryBuilder("getRules");
      $queryBuilder.find("input").blur();
      const event = $.Event("apply");
      $queryBuilder.trigger(event, [table, rules]);
      if (!event.isDefaultPrevented()) {
        if (queryBuilderHashKey) {
          window.localStorage.setItem(queryBuilderHashKey, JSON.stringify(rules));
        }
        table.dataTable().api(true).draw();
      }
    });

    const qbPresets = $(`#${tableId}_querybuilder_presets`);
    if (qbPresets) {
      initializeQueryBuilderPresets(table, tableId, qbPresets);
    }
  };

  const initializeFormFilter = function (formFilter, table) {
    table.data("datatable.formFilter", formFilter.first());

    $(formFilter).on("submit", (e) => {
      table.dataTable().api(true).draw();
      e.preventDefault();
      e.stopPropagation();
    });

    table.on("preXhr.dt", function (e, xhrSettings, xhrData) {
      const keyCounter = {};
      $(this).data("datatable.formFilter").serializeArray().forEach((item) => {
        let submitKey = item.name;
        if (xhrData[item.name]) {
          keyCounter[item.name] = keyCounter[item.name] || 0;
          keyCounter[item.name]++;

          submitKey = submitKey.replace("[]", `[${keyCounter[item.name]}]`);
        }

        xhrData[submitKey] = item.value;
      });
    });
  };

  const createTable = function ($obj, options, data) {
    let responsiveHelper;

    const baseCallback = function (row, rowData) {
      responsiveHelper.createExpandIcon(row);

      let url = null;

      if ($(row).attr("data-url")) {
        url = $(row).attr("data-url");
      }

      if (typeof rowData.url !== "undefined" && rowData.url) {
        ({ url } = rowData);
      }

      if (typeof rowData.id !== "undefined" && rowData.id) {
        $(row).attr("data-id", rowData.id);
      }

      if (
        options.linkMode === "row"
                || options.linkMode === "row-button"
      ) {
        appendLinkRow($(row), url, rowData);
      }
    };

    const rowCallbacks = [];
    rowCallbacks.push(baseCallback);
    rowCallbacks.push((row, rowData, index) => {
      $obj.trigger("DataTable:rowCallback", [row, rowData, index]);
    });

    const drawCallbacks = [];
    drawCallbacks.push(() => {
      responsiveHelper.respond();
    });
    drawCallbacks.push((...rest) => {
      $obj.trigger("DataTable:drawCallback", rest);
    });

    const initComplete = [];
    initComplete.push(() => {
      rebindSearchInput($obj.data("dataTable"));
    });
    initComplete.push((...rest) => {
      $obj.trigger("DataTable:initComplete", rest);
    });

    const fnServerParams = [];
    const preDrawCallbacks = [];

    preDrawCallbacks.push(() => {
      if (!responsiveHelper) {
        responsiveHelper = new window.ResponsiveDatatablesHelper(
          $obj,
          settings.breakpointDefinition
        );
      }
    });
    preDrawCallbacks.push((...rest) => {
      $obj.trigger("DataTable:preDrawCallback", rest);
    });

    const preInitCallback = [];
    preInitCallback.push((...rest) => {
      $obj.trigger("DataTable:preInitCallback", rest);
    });

    const tableId = $obj.attr("id");
    const defaultOptions = settings.defaults || {};
    const dataOptions = options || {};
    const jsonOptions = jsOptions($obj.next(`script[id="${tableId}_view-options"]`));

    [defaultOptions, dataOptions, jsonOptions].forEach((opt) => {
      if (typeof opt.rowCallback === "function") {
        rowCallbacks.push(opt.rowCallback);
      }
      if (typeof opt.drawCallback === "function") {
        drawCallbacks.push(opt.drawCallback);
      }
      if (typeof opt.initComplete === "function") {
        initComplete.push(opt.initComplete);
      }
      if (typeof opt.preDrawCallback === "function") {
        preDrawCallbacks.push(opt.preDrawCallback);
      }
      if (typeof opt.preInitCallback === "function") {
        preInitCallback.push(opt.preInitCallback);
      }
      if (typeof opt.fnServerParams === "function") {
        fnServerParams.push(opt.fnServerParams);
      }
    });

    fnServerParams.push((serverData) => {
      if (!serverData) {
        return;
      }

      let i;
      let l;
      const orderCols = [];
      if (Array.isArray(serverData.order)) {
        for (i = 0, l = serverData.order.length; i < l; i++) {
          orderCols.push(serverData.order[i].column);
        }
      }

      if (Array.isArray(serverData.columns)) {
        for (i = 0, l = serverData.columns.length; i < l; i++) {
          const col = serverData.columns[i];
          let includeCol = false;
          let includeSearchable = false;
          let includeSearch = false;
          if (orderCols.indexOf(i) > -1) {
            // Wenn sortiert wird, wird die Spalte mit [data]-Wert benötigt
            includeCol = true;
          }
          if (col.search && col.search.value) {
            // Ansonsten können Spalten ohne Suche ignoriert werden
            includeSearchable = true;
            includeSearch = true;
            includeCol = true;
          }
          if (serverData.search && serverData.search.value && col.searchable) {
            // Wenn global gesucht wird, wird die Spalte mit [data]-Wert benötigt
            includeSearchable = true;
            includeCol = true;
          }

          if (!includeCol) {
            serverData.columns[i] = undefined;
          } else {
            delete col.name;
            delete col.orderable;
            if (!includeSearchable) {
              delete col.searchable;
            }
            if (!includeSearch) {
              delete col.search;
            }
          }
        }
      }
    });

    options = $.extend(
      true,
      {},
      defaultOptions,
      {
        autoWidth: false
      },
      jsonOptions,
      dataOptions,
      {
        preDrawCallback(...rest) {
          for (let i = 0; i < preDrawCallbacks.length; i++) {
            preDrawCallbacks[i].apply(this, rest);
          }
        },
        rowCallback(row, rowData, index) {
          for (let i = 0; i < rowCallbacks.length; i++) {
            rowCallbacks[i](row, rowData, index);
          }
        },
        drawCallback(oSettings) {
          for (let i = 0; i < drawCallbacks.length; i++) {
            drawCallbacks[i](oSettings);
          }
        },
        initComplete(initSettings, json) {
          for (let i = 0; i < initComplete.length; i++) {
            initComplete[i](initSettings, json);
          }
        },
        fnServerParams(xhrData) {
          for (let i = 0; i < fnServerParams.length; i++) {
            fnServerParams[i].call(this, xhrData);
          }
        }
      },
    );
    $obj.data("options", options);

    // Für alle Datatables aktiv, auch wenn keine qbConfiguration gesetzt, da mehrere DataTables sich einen queryBuilder
    // teilen können.
    fnServerParams.push((aoData) => {
      const qb = $obj.data("queryBuilder");
      if (qb) {
        aoData.qb = qb.queryBuilder("getRules");
      }
    });
    if (options.qbConfiguration) {
      if (options.qbConfiguration.deferLoading) {
        options.deferLoading = 0;
      }
      initializeQueryBuilder(tableId, $obj);
    }

    const formFilter = $(".datatable-form-filter").filter((index, form) => $(form).data("datatable") === tableId);
    if (formFilter.length) {
      initializeFormFilter(formFilter, $obj);
    }

    const pluginFiles = $obj.data("pluginFiles") || [];
    pluginFiles.forEach($.getScript);

    if (_.has(options, "ajax") && !_.isEmpty(options.ajax.url)) {
      if (!_.has(options, "processing")) {
        options.processing = true;
      }
      if (!_.has(options, "serverSide")) {
        options.serverSide = true;
      }
    }

    if (data) {
      options = $.extend(true, {}, options, {
        data
      });
    }

    const pluginFilter = new ColumnFilterPlugin($obj, settings, options);
    const dtOptions = prepareOptions(_.cloneDeep(options));

    if (options.linkMode === "button" || options.linkMode === "row-button") {
      appendLinkButton($obj, dtOptions);
    }

    const dataTable = $obj.DataTable(dtOptions);
    pluginFilter.init(dataTable);
    $obj.data("options", options).data("dataTable", dataTable);
    for (let i = 0; i < preInitCallback.length; i++) {
      preInitCallback[i].call($obj[0], dataTable, settings);
    }

    return dataTable;
  };

  return function (App, config) {
    settings = $.extend(true, {}, settings, config);

    AppContainer = App;

    let collector = [];

    $.fn.dataTableExt.sErrMode = "throw";

    createTableToolsExports();

    attachFilters();

    this.init = function () {
      this.refresh(document);
    };

    this.refresh = function (node) {
      if ($.fn.dataTable) {
        $(node).find(settings.selector).each(function () {
          const $obj = $(this);

          const options = $obj.data("options") || {};
          $obj.trigger("DataTable:preCreate", [options]);
          collector.push(createTable($obj, options));
        });
      }
    };

    this.createTable = function ($obj, options, data) {
      const table = createTable($obj, options, data);

      collector.push(table);

      return table;
    };

    this.destroy = function () {
      for (let i = 0; i < collector.length; i++) {
        if (collector[i] && collector[i].destroy) {
          if (collector[i].table().node()) {
            collector[i].destroy();
          }
        }
      }
      collector = [];
    };
  };
}(window));

export default DataTables;
