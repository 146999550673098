import "jquery-datetimepicker";

export default function (app, config = {}) {
  const originalDateTimePicker = jQuery.fn.datetimepicker;
  jQuery.fn.datetimepicker = function (opt, opt2) {
    if (typeof opt === "undefined" || typeof opt === "object") {
      opt = Object.assign({}, jQuery.fn.datetimepicker.defaults, opt || {});
    }
    $(this).filter("input").each(function () {
      if (opt.inputClass) {
        $(this).addClass(opt.inputClass);
      }

      if (opt.inputAutocomplete) {
        $(this).attr("autocomplete", opt.inputAutocomplete);
      }
    });
    return originalDateTimePicker.call(this, opt, opt2);
  };

  jQuery.fn.datetimepicker.defaults = {
    dayOfWeekStart: 1,
    inputAutocomplete: "off",
    inputClass: "jsdatetimepicker-input",
    ...config
  };
  return { init: () => {} };
}
