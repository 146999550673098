import $ from "jquery";
// require("@SmartAdminBundle/js/plugin/sparkline/jquery.sparkline.js");

const defaults = {
  selector: ".sparkline",
  defaults: {
    barColor: "#0000f0",
    type: "bar",
    height: "26px",
    barWidth: 5,
    barSpacing: 2,
    stackedBarColor: ["#a90329", "#0099c6", "#98aa56", "#da532c", "#4490b1", "#6e9461", "#990099", "#b4cad3"],
    negBarColor: "#a90329",
    zeroAxis: "false"
  }
};

export default function (app, config) {
  const settings = $.extend(true, {}, defaults, config);

  this.init = function () {
    if (typeof $.fn.sparkline !== "function") {
      return;
    }

    $(settings.selector).not(":has(> canvas)").each(function () {
      const $obj = $(this);
      const sparkDataOptions = {};

      $.each($obj.data(), (key, value) => {
        if (key.indexOf("sparkline") !== 0) {
          return;
        }

        key = key.substr(9);
        key = key.slice(0, 1).toLowerCase() + key.slice(1);

        sparkDataOptions[key] = value;
      });

      const options = $.extend(true, {}, settings.defaults || {}, {
        barColor: $obj.css("color") || settings.defaults.barColor
      }, $obj.data("options"), sparkDataOptions);

      $obj.sparkline("html", options);
    });
  };

  this.destroy = $.noop;
}
