import _ from "lodash";

const defaults = {
  selector: ".plot-chart",
  defaults: {}
};

export default function (app, config) {
  const settings = $.extend(true, {}, defaults, config);

  let collector = [];

  // Compensate for chart placeholder elements with display: none;
  //  and thus no height and width values which would throw an error in flot charts.
  //  Therefore move the element to an visibility: hidden; container, create the
  //  chart there and put it back.
  // http://stackoverflow.com/questions/5427725/flot-graph-does-not-render-when-parent-container-is-hidden
  const fplot = function (e, data, options) {
    let jqParent;
    let jqHidden;

    if (typeof e.context.offsetWidth === "undefined" || e.context.offsetWidth <= 0
      || typeof e.context.offsetHeight === "undefined" || e.context.offsetHeight <= 0) {
      jqParent = $(e).parent();
      jqHidden = $('<div style="visibility:hidden"></div>');
      $("body").append(jqHidden);
      jqHidden.append(e);
    }

    const plot = $.plot(e, data, options);

    if (jqParent) {
      jqParent.append(e);
      jqHidden.remove();
    }

    return plot;
  };

  this.init = function () {
    if (typeof $.plot !== "function") {
      return;
    }
    $(settings.selector).each(function () {
      const $obj = $(this);

      const values = $obj.data("values");

      const options = $.extend(true, {}, settings.defaults || {}, $obj.data("options"));

      if (values && options) {
        const plot = fplot($obj, values, options);

        const debounced = _.debounce((target) => {
          target.removeClass("active");
        }, 3000);

        if (typeof $obj.data("plotclickTarget") === "function") {
          $obj.on("plotclick", (event, pos, item) => {
            if (!item) {
              return;
            }
            const target = $obj.data("plotclickTarget")(event, pos, item);
            if (target.length) {
              target.addClass("active");
              $("html, body").animate({
                scrollTop: target.offset().top - 180
              }, 500);
              debounced(target);
            }
          });
        }

        collector.push(plot);
      }
    });
  };

  this.destroy = function () {
    for (let i = 0; i < collector.length; i++) {
      collector[i].destroy();
    }
    collector = [];
  };
}
