import "easy-pie-chart";

const defaults = {
  selector: ".easy-pie-chart",
  defaults: {}
};

export default function (app, config) {
  const settings = $.extend(true, {}, defaults, config);

  this.init = function () {
    if ($.fn.easyPieChart) {
      $(settings.selector).each(function () {
        const $this = $(this);
        const barColor = $this.css("color") || $this.data("pie-color");
        const trackColor = $this.data("pie-track-color") || "#eeeeee";
        const size = parseInt($this.data("pie-size"), 2) || 25;

        const options = $.extend(true, {}, {
          barColor,
          trackColor,
          scaleColor: false,
          lineCap: "butt",
          lineWidth: parseInt(size / 8.5, 2),
          animate: 1500,
          rotate: -90,
          size,
          onStep(value) {
            this.$el.find("span").text(value);
          }
        }, $this.data("options"));

        $this.easyPieChart(options);
      });
    }
  };

  this.destroy = function () {
    $(settings.selector).empty();
  };
}
