export default function () {
  this.init = function () {
    // Form submit button loading icon and blocking
    $(document).on("submit.formLoader", "form", function (event) {
      if ($(this).data("isLoading")) {
        // eslint-disable-next-line
        console.warn('Resubmitting of form prevented as its already submitting.');
        event.preventDefault();
        return false;
      }

      if (event.isDefaultPrevented()) {
        return true;
      }

      $(this).data("isLoading", true);

      const $buttons = $(this).find('button[type="submit"]');

      // We cannot disable the original button, otherwise its value won't be submitted.
      // Important for forms with multiple buttons
      $buttons.each(function () {
        const $button = $(this);
        const $fakeButton = $button.clone();

        if ($fakeButton.find("span.btn-label").length) {
          const $icon = $('<i class="fa fa-circle-o-notch fa-spin loading" />');

          $fakeButton.find("span.btn-label i").hide();
          $fakeButton.find("span.btn-label").append($icon);
        }

        $fakeButton
          .addClass("disabled js-loading-button-fake")
          .hide()
          .attr({
            disabled: "disabled"
          })
          .insertAfter($button);

        $fakeButton.after(" ");

        $button
          .addClass("js-loading-button")
          .hide();

        $fakeButton.show();
      });
      return true;
    });

    // Delete button functionality
    if (window.app_delete_button_selector !== undefined) {
      $(document).on("click.delete_button", window.app_delete_button_selector, function () {
        const $button = $(this);

        $.SmartMessageBox({
          title: `<i class="fa fa-times txt-color-red"></i> ${$button.data("title")}`,
          content: $button.data("content"),
          buttons: `[${$button.data("cancel")}][${$button.data("delete")}]`
        }, (buttonPressed) => {
          if (buttonPressed === $button.data("delete")) {
            $.ajax({
              type: "post",
              url: $button.data("ajax-href"),
              success(response) {
                if (!response.error) {
                  window.location.href = response.forward;
                } else {
                  $.smallBox({
                    title: `Internal server error (${response.error})!`,
                    content: "",
                    color: "#c79121",
                    iconSmall: "fa fa-warning",
                    timeout: 5000
                  });
                }
              },
              error() {
                $.smallBox({
                  title: "Internal server error!",
                  content: "",
                  color: "#c79121",
                  iconSmall: "fa fa-warning",
                  timeout: 5000
                });
              }
            });
          }
        });
      });
    }

    if (!$("body").hasClass("menu-on-top") && typeof $.fn.jarvismenu !== "undefined") {
      $("nav ul:not(.jarvismenu)").addClass("jarvismenu").jarvismenu({
        accordion: window.menu_accordion || true,
        speed: window.menu_speed || true,
        closedSign: '<em class="fa fa-plus-square-o"></em>',
        openedSign: '<em class="fa fa-minus-square-o"></em>'
      });
    }
  };

  this.destroy = function () {
    $(document).off("submit.formLoader");

    if (typeof window.app_delete_button_selector !== "undefined") {
      $(document).off("click.delete_button");
    }
  };
}
