import $ from "jquery";

const defaults = {
  table: {
    selector: ".table-active > tbody > tr"
  },
  tags: {
    selector: "input[data-role=tagsinput], select[multiple][data-role=tagsinput]"
  }
};

const BootstrapAdditions = (function () {
  function tableActiveChecked() {
    $(this).toggleClass("table-active-checked");
  }

  return function () {
    this.init = function () {
      $(document).on("click", defaults.table.selector, tableActiveChecked);

      if (undefined !== $.fn.tagsinput) {
        $(defaults.tags.selector).tagsinput();
      }
    };

    this.destroy = function () {
      $(document).off("click", defaults.table.selector, tableActiveChecked);

      if (undefined !== $.fn.tagsinput) {
        $(defaults.tags.selector).tagsinput("destroy");
      }
    };
  };
}());

export default BootstrapAdditions;
